<template>
  <div class="flex flex-col h-screen">
    <header class="w-full z-10 top-0 sticky p-4 x-bg-white/[.6]">
      <div class="text-2xl text-left lowercase font-semibold text-sky-500 logo-font">
       Nik Khilnani
      </div>
    </header>
    <main class="flex-1">
      <router-view class="z-1"/>
    </main>
    <footer class="w-full z-50 float-root px-4 py-3 sticky bottom-0 bg-black">
      <div id="nav" class="w-full float-left">
        <span class="text-lg sm:text-base">
          <router-link to="/" active-class="active" class="px-1 text-sky-500 hover:text-sky-200">Art</router-link>
          <span class="text-sky-700">
           |
          </span>
          <router-link to="/about" active-class="active" class="px-1 text-sky-500 hover:text-sky-200">About</router-link>
          <span class="text-sky-700">
           |
          </span>
  <!--
          <router-link to="/contact" active-class="active">
            <span class="hidden sm:inline px-1 text-base text-sky-500 hover:text-sky-200">
              Contact
            </span>
          </router-link>
          <span class="hidden sm:inline text-sky-700">
            |
          </span>
          <router-link to="/contact" active-class="active">
            <i class="bi-envelope-fill px-2.5 sm:px-2 sm:hidden text-sky-500 hover:text-sky-200"></i>
          </router-link>
  //-->
          <a href="https://instagram.com/nikkhilnani/" target="_blank">
            <i class="bi-instagram px-2.5 sm:px-2 text-sky-500 hover:text-sky-200"></i>
          </a>
          <a href="https://github.com/khilnani/" target="_blank">
            <i class="bi-github px-2.5 sm:px-2 text-sky-500 hover:text-sky-200"></i>
          </a>
          <a href="https://linkedin.com/in/khilnani/" target="_blank">
            <i class="bi-linkedin px-2.5 sm:px-2 text-sky-500 hover:text-sky-200"></i>
          </a>
        </span>
      </div> 
    </footer>
  </div>
</template>

<style scoped>
  button {
    font-weight: bold;
  }

 #nav #routes.router-link-active,
 #nav #routes.router-link-exact-active {
   @apply hover:text-sky-200;
 }
</style>

<script>
  
export default {
  data () {
    return {
    }
  }
}
</script>