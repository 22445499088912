import { createApp } from 'vue'

import {marked} from 'marked'
import axios from 'axios';
import VueAxios from 'vue-axios'

import './assets/main.css'

import App from './App.vue'
import router from './router'

const markedMixin = {
    methods: {
         md: function (input) {
            return marked (input);
        },
    },
};

createApp(App)
  .mixin(markedMixin)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app')
