<template>
<!-- 
  <div id="my-reform"></div>
  <div class="w-full sm:w-3/4 md:w-3/5 xl:w-3/6 h-full p-10 p-10 mx-auto px-auto">
    <article class="align-top text-left prose max-w-none">
      <h1 class="text-3xl font-bold">Thanks for visiting</h1>
      <div>Feel free to reach out to me using the form below.</div>
      </article>
    <iframe 
      class="h-full w-full mt-2 p-6 pb-28 border-hidden" 
      src="https://dwsicfhm7wy.typeform.com/to/pGfwg8sA"
      frameBorder="0" scrolling="auto"
      >
    </iframe>
  </div>
//-->
  <div class="w-screen h-full bg-[#D9F2FD] overflow-hidden ">
    <iframe 
      class="h-full w-screen mt-2 border-hidden rounded-xl bg-[#D9F2FD]" 
      src="https://docs.google.com/forms/d/e/1FAIpQLSdk47KDD9aVCMMaDilzSg-2uP-nIxKIRZjyhjSp-5FVdnDbgA/viewform"
      frameBorder="0" scrolling="auto"
      >
    </iframe>
  </div>
</template>

<script>
  // https://dashboard.reform.app/forms/d45ef93d-f88a-439e-9c3b-d279c0b43ce5/share/embed
  window.Reform=window.Reform||function(){(Reform.q=Reform.q||[]).push(arguments)};

  export default {
    data () {
      return {
      };
    },
    created() {
      console.log("created Contact");
          Reform('init', {
          url: 'https://forms.reform.app/1e6IKb/khilnani/7s40a6',
          target: '#my-reform',
          background: 'transparent',
      })
    }
  }
</script>