<template>
  <div class="w-full h-screen absolute left-0 top-0 right-0 bg-image" 
    x-v-bind:style="{ 'background-image': 'url(' + currentImageStyle() + ')' }"
  >  
    <img
        :src="imageBottom.src"
        class="object-cover object-center w-screen h-screen absolute left-0 top-0 right-0"
        :class="imageBottom.cl"
      />  
    
    <img
        :src="imageTop.src"
        v-on:load="onImageLoaded" 
        class="object-cover object-center w-screen h-screen absolute left-0 top-0 right-0"
        :class="imageTop.cl"
      /> 

    <div class="flex w-full h-screen align-middle float-root absolute left-0 top-0 right-0 flex flex-col justify-end items-center">
      <span x-v-if="imageTitle != ''"
        class="bg-black/[0.7] sticky bottom-16 w-[90%] sm:w-[80%] lg:w-[60%] xl:w-[50%] text-sm text-white rounded-lg text-center p-2 transition-all ease-in-out duration-500"
        :class="imageCaptionClass"
        >
        <!--
        <div class="text-base md:text-lg font-semibold">{{ imageTitle }}</div>
        //-->
        <div class="imageDescription">
          <span v-html="imageDescription"></span>
        </div>
      </span>
      <!--
      <div class="sticky bottom-12 h-2 sm:h-1 w-full overflow-hidden relative">
        <div class="h-full absolute bottom-0 bg-slate-500/[0.4]" :class="autoplaying ? 'w-full' : 'w-0'"></div>
        <div class="h-full absolute bottom-0 rounded-r bg-sky-500" :class="(autoplaying && imageLoaded) ? 'w-full transition-all ease-in-out duration-[5000ms]' : 'w-0'"></div>
      </div>
      //-->
    </div>

    <div class="z-40 grid grid-cols-6 grid-rows-6 flex w-full h-screen absolute left-0 top-0 right-0">
      <div class="col-start-1 col-span-6 row-start-1 row-span-1">
        <div class="sticky top-0 h-1 w-full overflow-hidden relative">
          <div class="h-full absolute bottom-0 bg-slate-400/[0.5]" :class="autoplaying ? 'w-full' : 'w-0'"></div>
          <div class="h-full absolute bottom-0 rounded-r bg-sky-500" :class="(autoplaying && imageLoaded) ? 'w-full transition-all ease-in-out duration-[5000ms]' : 'w-0'"></div>
        </div>
        <div class="w-full sticky top-0">
          <button @click="autoplaying ? cancelAutoplay() : autoplay()" class="text-right pt-3 pr-2 sm:pr-4 absolute right-0">
            <i v-if="autoplaying" class="bi-pause-circle-fill text-sky-500 hover:text-sky-400 text-3xl"></i>
            <i v-if="!autoplaying" class="bi-play-circle-fill text-sky-500 hover:text-sky-400 text-3xl"></i>
          </button>
        </div>
      </div>
      <div class="col-start-1 col-span-1 row-start-2 row-span-4">
        <div class="h-full relative">
          <button @click="prev(true)" x-v-if="hasPrev()" class="text-left h-full w-[75%] sm:w-[50%] bg-transparent hover:bg-slate-500/[0.1] rounded-xl absolute left-0">
            <i class="bi-arrow-left-square-fill text-sky-500 hover:text-sky-400 text-3xl px-2 sm:px-5"></i>
          </button>
        </div>
      </div>
      <div class="col-start-2 col-span-4 row-start-2 row-span-4 flex justify-center items-center">
          <button :class="(!imageLoaded || !dataLoaded) ? 'opacity-1' : 'opacity-0'" type="button" class="flex transition-opacity fade-in-out duration-500 delay-300 text-white bg-black/[0.6] rounded-xl py-1 px-2 space-x-1.5" disabled>
            <i class="bi-arrow-clockwise text-base animate-spin"></i>
            <span>Loading ...</span>
          </button>
      </div>
      <div class="col-start-6 col-span-1 row-start-2 row-span-4">
        <div class="h-full relative">
          <button @click="next(true)" x-v-if="hasNext()" class="text-right h-full w-[75%] sm:w-[50%] bg-transparent hover:bg-slate-500/[0.1] rounded-xl absolute right-0">
            <i class="bi-arrow-right-square-fill text-sky-500 hover:text-sky-400 text-3xl px-2 sm:px-5"></i>
           </button>
        </div>
      </div>
      <div class="col-start-1 col-span-6 row-start-6 row-span-1">
      </div>
    </div>
    
</div>
  
</template>

<style scoped>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
    
  @layer components {
    .bg-image {
      @apply w-screen h-screen bg-center bg-repeat bg-cover bg-fixed bg-white;
      touch-action: pan-y;
    }
  }
</style>

<script >  
  import { store } from '../store.js'
  //import artJson from '@/json/art.json'

  const DURATION = 5000;
  const TRANSITION = 500;
  const EMPTY_MAGE = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=";

  export default {
    data: () => ({
      store,
      art: [],     
      autoplayId: -1,  
      autoplaying: store.autoplaying,
      DURATION: DURATION,
      _index: -1,
      dataLoaded: false,
      imageTitle: '',
      imageDescription: '',
      imageCaptionClass: 'opacity-0',
      imageLoaded: false,
      imageTop: {
        src: EMPTY_MAGE,
        cl: 'opacity-0',
      },
      imageBottom: {
        src: EMPTY_MAGE,
        cl: '',
      }
    }),
    beforeRouteLeave(to, from) {
      console.log('beforeRouteLeave Art');
      console.log(from.params);
      store.index = Number(from.params.index);
    },
    mounted() {
      console.log('mounted');
    },
    unmounted() {
      console.log('unmounted');
      this.suspendAutoplay();
    },
    async created() {
      console.log("created");

      this.dataLoaded = false;
      var response = await this.axios.get('/art/art.json');
      console.log('created - json', response);
      var updated = await Number(response.data.updated);
      this.art = await response.data.content;
      this.dataLoaded = true;
      
      //var index = this.randomIndex();
      var index = 0;
      var replaceRoute = true;

      console.log('created - updated', updated, 'store.visited', store.visited);
      if(updated > store.visited) {
        index = store.index = 0;
        console.log('created - new content, resetting index');
      } else {
        console.log('created - no new content, using store');
      }
      
      console.log('created - store.index', store.index);
      if(store.index > -1) 
      {
        index = store.index;
        replaceRoute = true;  // skip router
        console.log('created - using store.index', store.index);
      }      
      console.log('created - this.$route.params.index', this.$route.params.index);
      if ("index" in this.$route.params && 
          this.$route.params.index && this.$route.params.index != "")
      {
        index = Number(this.$route.params.index);
        replaceRoute = true;
        console.log('created - using this.$route.params.index', this.$route.params.index);
      }      
      this.display(index, replaceRoute);   
      this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          // react to route changes...
          console.log('toParams');
          console.log(toParams);
          if ("index" in toParams && toParams.index )     {
            this.display(Number(toParams.index), true);
          }
        }
      );      
    },
    methods: {
      onImageLoaded() {
        console.log("onImageLoaded - this._index", this._index, this.current());

        if(this._index > -1) {        
          // Update top image
          this.imageLoaded = true;
          this.imageTop.cl = 'opacity-100 transition-opacity ease-in duration-500';
          this.imageTitle = this.current().title;
          this.imageDescription = this.md(this.current().description);
  
          this.imageCaptionClass = 'opacity-100';
          // reset autoplay timer when image loads to avoid early change
          if(store.autoplaying) {
            this.autoplay();
          }
          // Once fade in done, update bottom image in preparation for the next transition
          // prevents flicker
          var ref = this;
          setTimeout(function() {
            ref.imageBottom.src = ref.currentImage();
          }, TRANSITION);  
        }
      },
      display(index, replace) {
        console.log('display', this._index, ' > ', index, replace);
        if(this._index == index) {
          console.log('display aborted - re-rendering same index');
          return;
        }
        // Set bottom image if not the first 
        if(this._index > -1)
        {
          this.imageBottom.src = this.currentImage();  
        }       
        // Hide top image
        this.imageLoaded = false;
        // change index
        store.index = this._index = index;
        // Change top image
        this.imageTop.src = this.currentImage();
        this.imageTop.cl = 'opacity-0';
        // clear caption
        this.imageCaptionClass = 'opacity-0';      
        // Handle routing
        if(replace !== undefined) {
          this.$router.push({ path: '/' + this._index, replace: (replace) ? true : false });
        }
      },
      autoplay() {
        console.log("autoplay");
        this.suspendAutoplay();
        store.autoplaying = this.autoplaying = true;
        var ref = this;
        ref.autoplayId = setInterval(function () {
          ref.next();
        }, DURATION);
        console.log("autoplaying id", this.autoplayId, "duration", DURATION);
      },
      suspendAutoplay() {
        console.log("suspendAutoplay");
        clearInterval(this.autoplayId);
      },
      cancelAutoplay() {
        console.log("cancelAutoplay");
        clearInterval(this.autoplayId);
        store.autoplaying = this.autoplaying = false;
      },
      randomIndex() {
        if (this.art && this.art.length > 0)
        {
          return Math.floor(Math.random() * this.art.length)
        }
        return 0;
      },
      index() {
        return this._index;
      },
      current() {
        console.log("current() - ", this._index );
        if (this.art && this.art.length > 0) {
          //console.log( this.art[this._index] );
          return this.art[this._index];
        } 
        return {image: '', title: '', description: ''};
      },
      currentImage() {
        var prefix = ( location.hostname.toLowerCase().indexOf('github.io') > -1 ) ? "/khilnani.org" : "";
        return prefix + this.current().image;
      },
      currentImageStyle() {
        return '"' + this.currentImage() + '"';
      },
      next() {
        console.log("next");
        if( this.hasNext() ) {
          this.display( this._index+1, false);
        } else {
          this.display( 0, false);
        }
      },
      prev() {
        console.log("prev");
        if( this.hasPrev() ) {
          this.display( this._index-1, false);
        } else {
          this.display( this.art.length - 1, false);
        }
      },
      hasNext() {
        return (this._index < this.art.length-1);
      },
      hasPrev() {
        return (this._index > 0);
      },
    },
    computed: {
    }
  }
</script>