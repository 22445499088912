<template>
  <div class="max-w-max h-full flex flex-wrap p-10 mx-auto px-auto">
    <div class="w-full sm:w-fit align-top text-center mb-10 lg:mb-0 mr-0 sm:mr-5 md:mr-10">
      <img class="w-44 sm:w-30 md:w-42 mx-auto border-solid border-2 rounded-xl" 
        src="/profile.jpg"/>
    </div>
    <article class="align-top text-left prose max-w-max xs:max-w-xs sm:max-w-xs md:max-w-md lg:max-w-xl">
      <h1 class="text-3xl font-bold">Hi 👋, I'm Nik.</h1>
      <div v-html="markdown"></div>
    </article>
  </div>
</template>

<style scoped>

</style>

<script>

export default {
  data () {
    return {
      markdown: this.md(` 
Welcome to my personal space on the internet.

When I’m not spending time with my family or pretending to be creative, I’m usually helping product, UX, and software teams grow, innovate, and deliver business value while learning and enjoying the journey.

I’m fortunate to be leading and supporting an amazing group of technologists at <a href="https://npr.org" target="_blank">National Public Radio (NPR)</a> who design and build the suite of digital products and systems used across <a href="https://npr.org" target="_blank">NPR</a> and public media stations in the USA. Additional information is available on <a href="https://www.linkedin.com/in/khilnani/" target="_blank">LinkedIn</a>.

Over my career, I have had the privilege of working with many talented people in *Media and News* at <a href="https://npr.org" target="_blank">NPR</a>, <a href="https://dmedmedia.disney.com" target="_blank">The Walt Disney Company</a>, <a href="https://www.nationalgeographic.com" target="_blank">National Geographic</a>, and <a href="https://www.gannett.com" target="_blank">Gannett</a> (<a href="https://usatoday.com" target="_blank">USAToday</a>), as well as *Online Education/eLearning* at <a href="https://www.stridelearning.com" target="_blank">Stride</a> (<a href="https://k12.com" target="_blank">K12</a>), <a href="https://lurn.com" target="_blank">Lurn</a>, and others. Additional information is available on <a href="https://www.linkedin.com/in/khilnani/" target="_blank">LinkedIn</a>.

Technology-wise, this website was hand-coded using <a href="https://vuejs.org/" target="_blank">Vue.js</a> and <a href="https://tailwindcss.com/" target="_blank">Tailwind CSS</a>. A few examples of other software I have written in my free time that will likely fail your tech review are available on <a href="https://github.com/khilnani" target="_blank">GitHub</a> - please be gentle. Links to a few projects are below:

- <a href="http://textchart.com" target="_blank">textchart.com</a> - Diagrams as code - A simple mobile first editor for PlantUML based technical diagrams built using AWS Lambda, Google Cloud Kubernetes, DynamoDB etc.
- <a href="http://timelymeetings.com" target="_blank">timelymeetings.com</a> - A Web and iOS app to help you end meetings on time. Built using React JS and Ionic Capacitor.
- <a href="http://quickedit.org" target="_blank">quickedit.org</a> - Text editor webapp that supports password based 256bit encryption and decryption of text & Dropbox login built using Backbone.js.

At <a href="https://npr.org" target="_blank">NPR</a>, we predominantly use React, Php, Node.js, and Java on AWS Cloud and Data Centers. Over my career, my teams and I have used React, Vue.js, React Native, Mobile Native, Python, Go, Java, Salesforce, AWS Cloud, Google Cloud, and various purpose-driven services and technologies. 

Feel free to reach out on <a href="https://www.linkedin.com/in/khilnani/" target="_blank">LinkedIn</a> or using the chat bubble at the bottom right of the screen.
It’s always good to hear from folks!\n\n\n
      `)
    }
  }
}

</script>
