import { createRouter, createWebHashHistory } from 'vue-router'

import Art from './components/Art.vue'
import About from './components/About.vue'
import Contact from './components/Contact.vue'

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/:index?',
      name: 'Art',
      component: Art,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
    }
  ]
})