// store.js
import { reactive, watch } from 'vue'

function hasLocalStorage(){ 
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch(e) {
      return false;
    }
}

export const store = reactive({
  index: (hasLocalStorage() && localStorage.getItem('index')) ? Number(localStorage.getItem('index')) : -1,
  autoplaying: (hasLocalStorage() && localStorage.getItem('autoplaying')) ? localStorage.getItem('autoplaying') == 'true' : true,
  visited: (hasLocalStorage() && localStorage.getItem('visited')) ? Number(localStorage.getItem('visited')) : -1,
})


watch(
  () => store.index,
  (next, prev) => {
    console.log("changed store.index to", next, ' from ', prev);
    if (hasLocalStorage() ) {
      localStorage.setItem('index', next);
      localStorage.setItem('visited', Date.now());
    }
  }
)

watch(
  () => store.autoplaying,
  (next, prev) => {
    console.log("changed store.autoplaying to", next, ' from ', prev);
    if (hasLocalStorage()) {
      localStorage.setItem('autoplaying', next);
    }
  }
)